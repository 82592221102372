import React from 'react';
import './footer.css';
const Footer = () => {
  return (
    <div className='footer_text'>
      © Ski Tim 2024
    </div>
  )
}

export default Footer